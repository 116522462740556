import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaRocket, FaCalendarCheck, FaTools, FaUsers, FaTwitter, FaGlobe, FaHandshake, FaGift, FaLock } from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Importer le hook pour les traductions

// Wrapper de la section Roadmap
const RoadmapWrapper = styled.section`
  padding: 6rem 2rem;
  background-color: #1e1e1e;
  color: white;
  text-align: center;
`;

// Titre de la Roadmap
const Title = styled.h2`
  font-size: 3.5rem;
  color: #FFD700;
  margin-bottom: 3rem;
`;

// Conteneur des étapes de la roadmap
const Timeline = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Ligne verticale centrale pour connecter les étapes
const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background-color: #FFD700;
  transform: translateX(-50%);
`;

// Chaque étape de la roadmap avec un style amélioré
const Step = styled(motion.div)`
  background-color: #2c2c2c;
  border-radius: 10px;
  width: 85%;
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
  position: relative;
  text-align: left;
  transform: ${(props) => (props.isLeft ? "translateX(-60%)" : "translateX(60%)")};
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:nth-child(odd) {
    align-self: flex-start;
  }

  &:nth-child(even) {
    align-self: flex-end;
  }
`;

// Icône pour chaque étape
const StepIcon = styled.div`
  position: absolute;
  top: -1.5rem;
  left: ${(props) => (props.isLeft ? "-2.5rem" : "auto")};
  right: ${(props) => (!props.isLeft ? "-2.5rem" : "auto")};
  background-color: #FFD700;
  padding: 1rem;
  border-radius: 50%;
  color: white;
  font-size: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

// Titre de chaque étape
const StepTitle = styled.h3`
  font-size: 1.8rem;
  color: #FFD700;
  margin-bottom: 0.5rem;
`;

// Description de chaque étape
const StepDescription = styled.p`
  font-size: 1.1rem;
  color: #bbbbbb;
  margin-bottom: 0.5rem;
`;

// Points clés ajoutés à chaque étape
const StepKeyPoints = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: #00A86B;

  li {
    margin: 0.5rem 0;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  li::before {
    content: '✔';
    margin-right: 0.5rem;
    color: #FFD700;
  }
`;

const Roadmap = () => {
    const { t } = useTranslation();

    return (
        <RoadmapWrapper id="roadmap">
            <Title>{t("roadmap.title")}</Title>
            <Timeline>
                <VerticalLine />

                {/* Étape 1 - Lancement du site et publication du contrat */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                    isLeft
                >
                    <StepIcon isLeft>
                        <FaGlobe />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step1.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step1.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step1.point1")}</li>
                        <li>{t("roadmap.step1.point2")}</li>
                    </StepKeyPoints>
                </Step>

                {/* Étape 2 - Création du compte Twitter */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                >
                    <StepIcon>
                        <FaTwitter />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step2.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step2.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step2.point1")}</li>
                        <li>{t("roadmap.step2.point2")}</li>
                        <li>{t("roadmap.step2.point3")}</li>
                    </StepKeyPoints>
                </Step>

                {/* Étape 3 - Prévente */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }}
                    viewport={{ once: true }}
                    isLeft
                >
                    <StepIcon isLeft>
                        <FaRocket />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step3.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step3.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step3.point1")}</li>
                        <li>{t("roadmap.step3.point2")}</li>
                        <li>{t("roadmap.step3.point3")}</li>
                    </StepKeyPoints>
                </Step>

                {/* Étape 4 - Verrouillage des Tokens du Créateur */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.4 }}
                    viewport={{ once: true }}
                >
                    <StepIcon>
                        <FaLock />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step4.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step4.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step4.point1")}</li>
                        <li>{t("roadmap.step4.point2")}</li>
                    </StepKeyPoints>
                </Step>

                {/* Étape 5 - Listing sur PancakeSwap et DEXTools */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.6 }}
                    viewport={{ once: true }}
                    isLeft
                >
                    <StepIcon isLeft>
                        <FaCalendarCheck />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step5.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step5.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step5.point1")}</li>
                        <li>{t("roadmap.step5.point2")}</li>
                    </StepKeyPoints>
                </Step>

                {/* Étape 6 - Expansion et Partenariats */}
                <Step
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.8 }}
                    viewport={{ once: true }}
                >
                    <StepIcon>
                        <FaHandshake />
                    </StepIcon>
                    <StepTitle>{t("roadmap.step6.title")}</StepTitle>
                    <StepDescription>
                        {t("roadmap.step6.description")}
                    </StepDescription>
                    <StepKeyPoints>
                        <li>{t("roadmap.step6.point1")}</li>
                        <li>{t("roadmap.step6.point2")}</li>
                    </StepKeyPoints>
                </Step>
            </Timeline>
        </RoadmapWrapper>
    );
};

export default Roadmap;