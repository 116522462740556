import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Animation de flash doux
const flash = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const AlertContainer = styled.div`
  background-color: #FFD700;
  color: #333333;
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  animation: ${flash} 2s ease-in-out infinite; /* Animation de flash */
  z-index: 999; /* S'assurer que l'alerte reste au-dessus */
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const CloseButton = styled.button`
  background-color: #333333;
  color: #FFD700;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #00A86B;
    color: white;
  }
`;

const Alert = ({ onClose, show }) => {
    return (
        <AlertContainer show={show}>
            <p>Connecte ton wallet pour faire partie des 1000 whitelistés et accéder à la prévente !</p>
            <CloseButton onClick={onClose}>Fermer</CloseButton>
        </AlertContainer>
    );
};

export default Alert;
