import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ReferralProgram } from "./SharedComponents";
import styled from "styled-components";
import { useTranslation } from "react-i18next"; // Import du hook pour les traductions

// Conteneur principal du profil
const ProfileContainer = styled.div`
  margin: 20px auto;
  padding: 30px;
  max-width: 800px;
  background: linear-gradient(135deg, #1e293b, #334155);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #ecf0f1;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;

const ProfileHeader = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #ffd700;
  margin-bottom: 20px;
`;

const WalletInfo = styled.div`
  margin: 20px 0;
  background: #334155;
  padding: 15px;
  border-radius: 8px;
  color: #e2e8f0;
  font-size: 1rem;
  word-wrap: break-word;
`;

const Badge = styled.span`
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: ${(props) =>
          props.status === "Whitelisted" || props.status === "En Lista Blanca" ? "#00FF88" : "#FFA500"};
  color: #1e293b;
  font-weight: bold;
  border-radius: 20px;
  font-size: 0.9rem;
`;

const Button = styled.button`
  background-color: #ffd700;
  color: #1e293b;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #00a86b;
    color: #ffffff;
    transform: scale(1.05);
  }
`;

const ErrorMessage = styled.p`
  color: #ff6347;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 20px;
`;

const LoadingMessage = styled.p`
  color: #ffd700;
  font-size: 1rem;
  margin-top: 20px;
`;

// Composant principal
const ProfileSection = () => {
    const { t } = useTranslation(); // Hook pour accéder aux traductions
    const [walletAddress, setWalletAddress] = useState(null);
    const [referrals, setReferrals] = useState([]);
    const [whitelistStatus, setWhitelistStatus] = useState(null);
    const [referrerAddress, setReferrerAddress] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    // Récupérer le paramètre `ref` depuis l'URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const ref = params.get("ref");
        if (ref) {
            setReferrerAddress(ref);
        }
    }, [location.search]);

    // Connecter le wallet et gérer le parrainage
    const connectWallet = async () => {
        if (!window.ethereum) {
            alert(t("errors.metamask_not_detected"));
            return;
        }

        try {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const wallet = accounts[0];
            setWalletAddress(wallet);
            setLoading(true);

            // Enregistrer le wallet et le parrainage
            await axios.post("/api/wallets", {
                walletAddress: wallet,
                referrerAddress: referrerAddress || null,
            });

            // Charger les données utilisateur après connexion
            fetchUserData(wallet);
        } catch (err) {
            console.error("Erreur lors de la connexion au wallet :", err);
            setError(t("errors.wallet_connection_failed"));
        } finally {
            setLoading(false);
        }
    };

    // Récupérer les données utilisateur
    const fetchUserData = async (wallet) => {
        try {
            setLoading(true);

            const referralsResponse = await axios.get(`/api/referrals/${wallet}`);
            setReferrals(referralsResponse.data);

            const whitelistResponse = await axios.get(`/api/whitelisted/${wallet}`);
            setWhitelistStatus(
                whitelistResponse.data.isWhitelisted
                    ? t("status.whitelisted")
                    : t("status.waiting_list")
            );
        } catch (err) {
            console.error("Erreur lors de la récupération des données utilisateur :", err);
            setError(t("errors.user_data_fetch_failed"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProfileContainer>
            {loading && <LoadingMessage>{t("messages.loading_data")}</LoadingMessage>}

            {walletAddress ? (
                <>
                    <ProfileHeader>{t("profile.welcome")}</ProfileHeader>
                    <WalletInfo>
                        <strong>{t("profile.wallet_address")}:</strong> {walletAddress}
                    </WalletInfo>

                    {/* Badge de statut */}
                    <Badge status={whitelistStatus}>
                        {whitelistStatus || t("status.loading")}
                    </Badge>

                    {/* Programme de parrainage */}
                    <ReferralProgram walletAddress={walletAddress} referrals={referrals} />
                </>
            ) : (
                <>
                    {referrerAddress && (
                        <p>
                            <strong>{t("profile.detected_referrer")}:</strong> {referrerAddress}
                        </p>
                    )}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <Button onClick={connectWallet}>{t("profile.connect_wallet")}</Button>
                </>
            )}
        </ProfileContainer>
    );
};

export default ProfileSection;
