import React from 'react';
import styled from 'styled-components';


// Container principal du whitepaper
const WhitepaperContainer = styled.div`
  padding: 40px;
  background: linear-gradient(135deg, #00A86B, #333333);
  min-height: 100vh;
  color: white;
`;

// Bouton Retour au site
const ReturnButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #FFD700;
  color: #333333;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #00A86B;
    color: white;
  }
`;

// Styles pour les cartes de contenu
const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 90%; /* Légèrement plus large */
  max-width: 900px; /* Limiter la largeur maximum */
  margin: 20px auto; /* Centrer les cartes */
`;

const SectionTitle = styled.h2`
  color: #FFD700;
  text-align: center;
  margin: 20px 0;
  font-size: 28px;
  text-transform: uppercase;
`;

const SectionContent = styled.p`
  color: #ecf0f1;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
`;

const TokenomicsTable = styled.table`
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #34495e;
  color: white;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #FFD700;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: white;
  text-align: center;
`;

const ContractCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 30px;
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 20px auto;
`;

const ContractLink = styled.a`
  color: #FFD700;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

const ContractIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

const SectionContentContract = styled.div`
  margin-bottom: 20px;
  color: #ecf0f1;
  font-size: 20px;
  line-height: 1.8;
`;

const Whitepaper = () => {
    return (
        <WhitepaperContainer>

            {/* Bouton Retour au site */}
            <ReturnButton onClick={() => window.location.href = '/'}>
                Retour au site
            </ReturnButton>

            {/* Introduction */}
            <Card>
                <SectionTitle>Introduction</SectionTitle>
                <SectionContent>
                    FrogoCoin est un memecoin conçu pour offrir une cryptomonnaie amusante et lucrative,
                    tout en intégrant des fonctionnalités uniques comme le staking,
                    la protection contre les bots, et une répartition équitable des tokens.
                </SectionContent>
            </Card>

            {/* Card for contract addresses */}
            <ContractCard>
                <SectionTitle>Adresse du contrat</SectionTitle>
                <SectionContentContract>
                    Adresse du contrat FrogoCoin sur la Binance Smart Chain (BSC) :
                    <br />
                    <ContractLink
                        href="https://bscscan.com/address/0x24d506848912EB60bC01434693D1Cd023F0C33cb"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ContractIcon>🔗</ContractIcon>0x24d506848912EB60bC01434693D1Cd023F0C33cb
                    </ContractLink>
                    <br />
                    Adresse des tokens bloqués du créateur :
                    <br />
                    <ContractLink
                        href="https://bscscan.com/address/0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ContractIcon>🔗</ContractIcon>0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323
                    </ContractLink>
                </SectionContentContract>
            </ContractCard>

            {/* Tokenomics */}
            <Card>
                <SectionTitle>Tokenomics</SectionTitle>
                <TokenomicsTable>
                    <thead>
                    <tr>
                        <TableHeader>Allocation</TableHeader>
                        <TableHeader>Pourcentage</TableHeader>
                        <TableHeader>Montant (tokens)</TableHeader>
                    </tr>
                    </thead>
                    <tbody>
                    <TableRow>
                        <TableCell>Prévente</TableCell>
                        <TableCell>50%</TableCell>
                        <TableCell>500,000,000,000</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Liquidité</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>250,000,000,000</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Développement</TableCell>
                        <TableCell>12.5%</TableCell>
                        <TableCell>125,000,000,000</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Créateur (Bloqué)</TableCell>
                        <TableCell>12.5%</TableCell>
                        <TableCell>125,000,000,000</TableCell>
                    </TableRow>
                    </tbody>
                </TokenomicsTable>
            </Card>

            {/* Staking */}
            <Card>
                <SectionTitle>Staking</SectionTitle>
                <SectionContent>
                    FrogoCoin offre un programme de staking avec des récompenses allant de 15% à 25%
                    en fonction de la durée du staking. Les tokens stakés contribuent à la stabilité
                    et à la sécurité du réseau, tout en offrant des gains attractifs aux détenteurs.
                </SectionContent>
            </Card>

        </WhitepaperContainer>
    );
};

export default Whitepaper;
