import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// Styled Component ReferralButton
export const ReferralButton = styled.button`
  margin-top: 10px;
  background-color: #FFD700;
  color: #1E293B;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #00A86B;
    color: #FFFFFF;
    transform: scale(1.05);
  }
`;

// Referral Program Wrapper
export const ReferralProgram = ({ walletAddress, referrals }) => {
    const { t } = useTranslation(); // Hook pour les traductions

    return (
        <ReferralSection>
            <h3>{t("referral_program.title")}</h3>
            <p>{t("referral_program.message")}</p>
            <p>{t("referral_program.bonus")}</p>
            {walletAddress && (
                <>
                    <ReferralLink>{`https://ofrog.xyz?ref=${walletAddress}`}</ReferralLink>
                    <ReferralButton onClick={() => generateReferralLink(walletAddress)}>
                        {t("referral_program.copy_button")}
                    </ReferralButton>
                </>
            )}

            {referrals && referrals.length > 0 && (
                <div>
                    <h4>{t("referral_program.your_referrals")}:</h4>
                    <ul>
                        {referrals.map((ref, index) => (
                            <li key={index}>
                                {t("referral_program.wallet")}: {ref.referred_wallet} | {t("referral_program.date")}: {new Date(ref.created_at).toLocaleDateString()}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </ReferralSection>
    );
};

// Function to Generate Referral Link
export const generateReferralLink = (walletAddress) => {
    const referralLink = `https://ofrog.xyz?ref=${walletAddress}`;
    navigator.clipboard.writeText(referralLink);
    alert(i18n.t("referral_program.link_copied")); // Appel direct à la fonction i18n pour afficher le message dans la bonne langue
};

// Airdrop Banner
export const AirdropBanner = () => {
    const { t } = useTranslation();
    return (
        <AirdropContainer>
            🎁 <strong>{t("airdrop.title")}</strong> {t("airdrop.message")}
        </AirdropContainer>
    );
};

// Styled Components
const ReferralSection = styled.div`
  margin-top: 20px;
  background: #334155;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const ReferralLink = styled.pre`
  background-color: #1E293B;
  padding: 10px;
  border-radius: 8px;
  color: #FFD700;
  font-size: 0.85rem;
  margin: 10px 0;
  overflow-x: auto;
  text-align: left;
`;

const AirdropContainer = styled.div`
  margin-top: 20px;
  padding: 15px;
  background: #FFD700;
  color: #1E293B;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
`;
