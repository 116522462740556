import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
    .use(HttpApi) // Charge les fichiers JSON
    .use(LanguageDetector) // Détecte automatiquement la langue
    .use(initReactI18next) // Initialise pour React
    .init({
        fallbackLng: "en", // Langue par défaut
        debug: true, // Activez uniquement en développement
        interpolation: {
            escapeValue: false, // React s'occupe de l'échappement des valeurs
        },
        backend: {
            loadPath: "/locales/{{lng}}/translation.json", // Chemin des fichiers de traduction
        },
    });

export default i18n;
