import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Empêche le défilement horizontal */
    scroll-behavior: smooth; /* Défilement fluide pour les ancres */
  }

  body {
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    overflow-y: auto; /* Défilement vertical uniquement si nécessaire */
  }

  button {
    font-family: 'Poppins', sans-serif;
  }
`;


export default GlobalStyle;
