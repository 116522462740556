import React from "react";
import styled from "styled-components";
import {
    FaFrog,
    FaRocket,
    FaLock,
    FaHandsHelping,
    FaFireAlt,
    FaPiggyBank,
    FaShieldAlt,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

// Wrapper pour la section description
const DescriptionWrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #2c2c2c;
  color: white;
  text-align: center;
`;

// Titre principal
const Title = styled.h2`
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 2rem;
`;

// Texte de description générale
const Text = styled.p`
  font-size: 1.3rem;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.7;
  span {
    color: #ffd700; /* Accentuation de certains termes */
    font-weight: bold;
  }
`;

// Conteneur pour les avantages
const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
`;

// Carte pour chaque avantage
const BenefitCard = styled.div`
  background-color: #333333;
  padding: 2rem;
  border-radius: 10px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px); // Animation au survol
  }
`;

// Icône pour chaque carte
const BenefitIcon = styled.div`
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 1rem;
`;

// Titre de chaque avantage
const BenefitTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
`;

// Description de chaque avantage
const BenefitDescription = styled.p`
  font-size: 1.1rem;
  color: #00a86b;
`;

const Description = () => {
    const { t } = useTranslation();

    const benefits = [
        {
            icon: <FaFrog />,
            title: t("benefits.entertainment_title"),
            description: t("benefits.entertainment_description"),
        },
        {
            icon: <FaRocket />,
            title: t("benefits.growth_title"),
            description: t("benefits.growth_description"),
        },
        {
            icon: <FaLock />,
            title: t("benefits.security_title"),
            description: t("benefits.security_description"),
        },
        {
            icon: <FaHandsHelping />,
            title: t("benefits.community_title"),
            description: t("benefits.community_description"),
        },
        {
            icon: <FaFireAlt />,
            title: t("benefits.burn_title"),
            description: t("benefits.burn_description"),
        },
        {
            icon: <FaPiggyBank />,
            title: t("benefits.staking_title"),
            description: t("benefits.staking_description"),
        },
        {
            icon: <FaLock />,
            title: t("benefits.creator_lock_title"),
            description: t("benefits.creator_lock_description"),
        },
        {
            icon: <FaShieldAlt />,
            title: t("benefits.lock_contract_title"),
            description: t("benefits.lock_contract_description"),
        },
    ];

    return (
        <DescriptionWrapper>
            <Title>{t("description.title")}</Title>
            <Text>
                {t("description.text.part1")} <span>{t("description.text.frogocoin")}</span>{" "}
                {t("description.text.part2")}{" "}
                <span>{t("description.text.dynamic_crypto")}</span>! <br />
                {t("description.text.part3")}{" "}
                <span>{t("description.text.unique_opportunity")}</span>{" "}
                {t("description.text.part4")}{" "}
                <span>{t("description.text.each_investor")}</span>{" "}
                {t("description.text.part5")}{" "}
                <span>{t("description.text.frogocoin")}</span>. {t("description.text.part6")}
            </Text>

            {/* Section des avantages */}
            <BenefitsContainer>
                {benefits.map((benefit, index) => (
                    <BenefitCard key={index}>
                        <BenefitIcon>{benefit.icon}</BenefitIcon>
                        <BenefitTitle>{benefit.title}</BenefitTitle>
                        <BenefitDescription>{benefit.description}</BenefitDescription>
                    </BenefitCard>
                ))}
            </BenefitsContainer>
        </DescriptionWrapper>
    );
};

export default Description;
