import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

// Wrapper pour la section FAQ
const FAQWrapper = styled.section`
  padding: 6rem 2rem;
  background-color: #1E1E1E;
  color: white;
  text-align: center;
`;

// Titre de la section FAQ
const Title = styled.h2`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 3rem;
`;

// Conteneur des questions et réponses
const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// Wrapper pour chaque question
const QuestionWrapper = styled.div`
  background-color: #2A2A2A;
  margin: 1rem 0;
  padding: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #333333;
  }
`;

// Titre de chaque question
const QuestionTitle = styled.h3`
  font-size: 1.5rem;
  color: #FFD700;
`;

// Réponse
const Answer = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.$isOpen ? "500px" : "0")};
  transition: max-height 0.5s ease;
  background-color: #333333;
  padding: ${(props) => (props.$isOpen ? "1rem" : "0")};
  border-radius: 5px;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  transition: opacity 0.3s ease, max-height 0.5s ease;
`;

// Texte des réponses
const AnswerText = styled.p`
  font-size: 1rem;
  color: #CCCCCC;  /* Couleur du texte des réponses */
  margin: 0;
`;

// Chevron icons with animation
const ChevronIcon = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.$isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const FAQ = () => {
    const [openQuestion, setOpenQuestion] = useState(null);
    const { t } = useTranslation(); // Initialisation de `t()` pour les traductions

    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <FAQWrapper id="faq">
            <Title>{t("faq.title")}</Title>
            <FAQContainer>
                {/* Question 1 */}
                <QuestionWrapper onClick={() => toggleQuestion(1)}>
                    <QuestionTitle>{t("faq.question1.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 1}>
                        {openQuestion === 1 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 1}>
                    <AnswerText>{t("faq.question1.answer")}</AnswerText>
                </Answer>

                {/* Question 2 */}
                <QuestionWrapper onClick={() => toggleQuestion(2)}>
                    <QuestionTitle>{t("faq.question2.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 2}>
                        {openQuestion === 2 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 2}>
                    <AnswerText>{t("faq.question2.answer")}</AnswerText>
                </Answer>

                {/* Question 3 */}
                <QuestionWrapper onClick={() => toggleQuestion(3)}>
                    <QuestionTitle>{t("faq.question3.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 3}>
                        {openQuestion === 3 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 3}>
                    <AnswerText>{t("faq.question3.answer")}</AnswerText>
                </Answer>

                {/* Question 4 */}
                <QuestionWrapper onClick={() => toggleQuestion(4)}>
                    <QuestionTitle>{t("faq.question4.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 4}>
                        {openQuestion === 4 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 4}>
                    <AnswerText>{t("faq.question4.answer")}</AnswerText>
                </Answer>

                {/* Question 5 */}
                <QuestionWrapper onClick={() => toggleQuestion(5)}>
                    <QuestionTitle>{t("faq.question5.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 5}>
                        {openQuestion === 5 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 5}>
                    <AnswerText>{t("faq.question5.answer")}</AnswerText>
                </Answer>

                {/* Question 6 */}
                <QuestionWrapper onClick={() => toggleQuestion(6)}>
                    <QuestionTitle>{t("faq.question6.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 6}>
                        {openQuestion === 6 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 6}>
                    <AnswerText>{t("faq.question6.answer")}</AnswerText>
                </Answer>

                {/* Question 7 */}
                <QuestionWrapper onClick={() => toggleQuestion(7)}>
                    <QuestionTitle>{t("faq.question7.title")}</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 7}>
                        {openQuestion === 7 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 7}>
                    <AnswerText>{t("faq.question7.answer")}</AnswerText>
                </Answer>
            </FAQContainer>
        </FAQWrapper>
    );
};

export default FAQ;
