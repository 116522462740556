// src/context/WalletContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserProvider } from 'ethers';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [walletAddress, setWalletAddress] = useState(null);
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        // Vérifier si le wallet est déjà connecté dans localStorage
        const savedWallet = localStorage.getItem('walletAddress');
        if (savedWallet) {
            setWalletAddress(savedWallet);
            setProvider(new BrowserProvider(window.ethereum));
        }
    }, []);

    const connectWallet = async () => {
        try {
            if (!window.ethereum) {
                alert('Veuillez installer MetaMask pour continuer');
                return;
            }
            const provider = new BrowserProvider(window.ethereum);
            const [account] = await provider.send('eth_requestAccounts', []);
            setWalletAddress(account);
            setProvider(provider);

            // Sauvegarder l'adresse dans le localStorage
            localStorage.setItem('walletAddress', account);
        } catch (error) {
            console.error('Erreur lors de la connexion au wallet:', error);
        }
    };

    const disconnectWallet = () => {
        setWalletAddress(null);
        setProvider(null);
        localStorage.removeItem('walletAddress');
    };

    return (
        <WalletContext.Provider value={{ walletAddress, provider, connectWallet, disconnectWallet }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    return useContext(WalletContext);
};
