import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll"; // Pour le défilement fluide vers les sections
import HeroSection from "./components/HeroSection";
import Description from "./components/Description";
import Tokenomics from "./components/Tokenomics";
import Roadmap from "./components/Roadmap";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Dashboard from "./pages/Dashboard";
import Whitepaper from "./components/Whitepaper";
import GlobalStyle from "./GlobalStyle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { WalletProvider } from "./context/WalletContext";

// Gestion de la redirection si un lien contient un paramètre `ref`
const RedirectHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const refParam = queryParams.get("ref");

        if (refParam) {
            navigate(`/dashboard?ref=${refParam}`);
        }
    }, [navigate]);

    return null; // Pas besoin de retour visuel
};

// Gestion du scroll vers les ancres internes si un hash est détecté
const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.replace("#", ""); // Récupérer l'id depuis le hash
        if (hash) {
            scroller.scrollTo(hash, {
                smooth: true,
                duration: 800,
                offset: -80, // Ajuster pour la hauteur de la Navbar
            });
        }
    }, [location]);

    return null; // Pas besoin de retour visuel
};

function App() {
    return (
        <WalletProvider> {/* Envelopper l'application avec WalletProvider */}
            <Router>
                {/* Gestion des styles globaux */}
                <GlobalStyle />

                {/* Gestion des redirections */}
                <RedirectHandler />

                {/* Gestion du scroll vers les sections */}
                <ScrollToSection />

                <Routes>
                    {/* Route principale : Page d'accueil */}
                    <Route path="/" element={
                        <div id="page-container">
                            <HeroSection />
                            <Description />
                            <Tokenomics />
                            <Roadmap />
                            <FAQ />
                            <Footer />
                        </div>
                    } />

                    {/* Route pour le dashboard */}
                    <Route path="/dashboard" element={<Dashboard />} />

                    {/* Route pour le whitepaper */}
                    <Route path="/whitepaper" element={<Whitepaper />} />

                    {/* Route fallback pour les pages inexistantes */}
                    <Route path="*" element={
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            textAlign: 'center'
                        }}>
                            <h1>404</h1>
                            <p>Page non trouvée. Retournez à la <a href="/">page d'accueil</a>.</p>
                        </div>
                    } />
                </Routes>
            </Router>
        </WalletProvider>
    );
}

export default App;
