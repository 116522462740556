// StatsChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const StatsChart = ({ data }) => {
    const chartData = {
        labels: data.map((entry) => entry.date),  // Assuming the 'date' is in the dataset
        datasets: [
            {
                label: 'Tokens Vendus',
                data: data.map((entry) => entry.tokens_purchased),
                fill: false,
                backgroundColor: '#00A86B',
                borderColor: '#FFD700',
                borderWidth: 2,
                tension: 0.3,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default StatsChart;
